
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Pageniation as Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const app = namespace("app");
const base = namespace("base");
const consult = namespace("consult");
@Component({
  components: { FileUpload, Editor }
})
@Table("loadListData")
export default class ConsultEdit extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  // 资讯
  @consult.Action repeatTitle;
  @consult.Action insertContent;
  @consult.Action updateContent;
  @consult.Action queryContentClassPage;
  @consult.Action queryContentDetail;
  @consult.Action insertContentClass;

  emptyToLine = emptyToLine;
  consultForm: any = {
    byLiner: "", //署名
    classCode: "", //资讯分类编码
    content: "", //资讯内容
    digest: "", //文章摘要
    release: false, //是否发布.true-发布,false-不发布(默认)
    releaseRangeList: [], //发布范围
    sketch: "", //缩略图.上传的数据不应该含有域名
    title: "" //资讯标题
  };
  contentRangeList: any[] = [];
  contentClassList: any[] = [];
  contentClassListCopy: any[] = [];
  dialogFormVisible: boolean = false;
  dayjs = dayjs;
  consultFormRules: any = {
    title: [
      { required: true, message: t("v210831.please-enter-information"), trigger: "blur" },
      { max: 50, message: t("v210831.up-to-50-max"), trigger: "blur" }
    ],
    classCode: [{ required: true, message: t("v210831.please-select-information"), trigger: "change" }],
    releaseRangeList: {
      type: "array",
      required: true,
      message: t("v210831.please-select-the"),
      trigger: "change"
    },
    byLiner: [
      { required: true, message: t("v210831.please-enter-a-name"), trigger: "blur" },
      { max: 50, message: t("v210831.up-to-50-max"), trigger: "blur" }
    ],
    sketch: { required: true, validator: this.checkFrontImage },
    digest: [
      { required: true, message: t("v210831.please-enter-a-digest"), trigger: "blur" },
      { max: 100, message: t("v210831.up-to-100-max"), trigger: "blur" }
    ],
    content: [{ required: true, message: t("v210831.please-enter-the-content"), trigger: "blur" }]
  };
  checkFrontImage(rule, value, callback) {
    if (this.consultForm.bannerType === "default") {
      callback();
    } else {
      if (!value) {
        callback(new Error(t("v210831.please-upload-pictures")));
      } else {
        callback();
      }
    }
  }
  get breadData() {
    return [
      { name: t("v210831.information-management"), path: "/consult/consult-list" },
      {
        name: this.isAdd ? t("v210831.new-information") : t("v210831.edit-information")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "consultAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  defaultBannerList: any = [];
  init() {
    if (!this.isAdd || this.$route.query.id) {
      this.queryContentDetail({ contentCode: this.id || this.$route.query.id }).then(data => {
        this.consultForm = data.data;
        this.classSearchValue = this.consultForm.className;
        this.defaultBannerList = [{ url: this.consultForm.sketch }];
      });
    }
    this.getContentClassList();
    // 发布范围字典List
    this.getDictionaryList("store_type").then(data => {
      this.contentRangeList = data;
    });
  }
  mounted() {}
  // 获取分类列表
  getContentClassList(callback?) {
    this.queryContentClassPage({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      let arr = data.data.list
        ? data.data.list.map(item => {
            item.label = item.className;
            item.value = item.classCode;
            return item;
          })
        : [];
      this.contentClassList = arr.concat();
      this.contentClassListCopy = arr.concat();
      callback && callback();
    });
  }
  // 添加资讯分类
  classSearchValue: string = ""; // 分类搜索keyword
  addNewClass() {
    (this.$refs["classSelect"] as any).blur();
    this.insertContentClass({ className: this.classSearchValue }).then(res => {
      this.$message.success(t("v210831.new-successfully"));
      this.consultForm.classCode = res.data;
      this.getContentClassList();
    });
  }
  handleSearchClass(val) {
    if (val) {
      this.classSearchValue = val;
      this.contentClassList = this.contentClassList.filter(item => {
        return item.label.includes(val);
      });
    } else {
      this.contentClassList = this.contentClassListCopy;
    }
  }
  handleChangeClas(e) {
    let name = "";
    this.contentClassList.forEach(item => {
      if (item.value === e) {
        name = item.label;
      }
    });
    this.classSearchValue = name;
  }
  // 上传图片
  handleBannerUploadSuccess(url) {
    (this.$refs.consultForm as any).validateField("sketch");
    this.getImageColor(url);
  }
  getImageColor(url) {
    console.log(url);
    const _this = this;
    let canvas = document.createElement("canvas"),
      ctxt = canvas.getContext && canvas.getContext("2d");
    var img = new Image();
    // 要求跨域处理
    img.crossOrigin = "Anonymous";
    img.src = url; //src也可以是从文件选择控件中取得。
    img.onload = () => {
      let [width, height] = getImageSize(img);
      canvas.height = height;
      canvas.width = width;
      ctxt.drawImage(img, 0, 0);
      var data = ctxt.getImageData(~~(width / 2), height - 2, 1, 1).data; //读取整张图片的像素。
      let [r, g, b] = data;
      console.log(33333, r, g, b);
      _this.consultForm.campaignImageColor = `rgb(${[r, g, b].join()})`;
    };
  }
  // 仅保存
  handleInsertContent() {
    this.consultForm.release = false;
    this.insertContentItem();
  }
  // 发布
  handleReleaseContent() {
    (this.$refs.consultForm as any).validate(valid => {
      if (valid) {
        this.dialogFormVisible = true;
      }
    });
  }
  handleInsert() {
    this.consultForm.release = true;
    this.insertContentItem();
  }
  // 提交
  insertContentItem() {
    (this.$refs.consultForm as any).validate(valid => {
      if (valid) {
        this.repeatTitle(this.consultForm).then(res => {
          if (res.data.repeat) {
            this.$message.warning(t("v210831.information-title-already"));
            this.dialogFormVisible = false;
          } else {
            if (this.isAdd) {
              // 新建
              this.insertContent(this.consultForm).then(() => {
                let message =
                  t("v210831.real-time-info") +
                  (this.consultForm.release ? t("v210831.release") : t("base.save")) +
                  t("v210831.success");
                this.$message.success(message);
                this.$router.go(-1);
              });
            } else {
              // 编辑
              this.updateContent(this.consultForm).then(() => {
                this.$message.success(t("v210831.information-edited-successfully"));
                this.$router.go(-1);
              });
            }
          }
        });
      }
    });
  }
}
