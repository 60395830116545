
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { editorUploadHandler } from "@common/src/utils/tools";
const common_base = namespace("common_base");
@Component({})
export default class Editor extends Vue {
  @common_base.Action getUploadToken;
  editorUploadHandler = editorUploadHandler;
}
