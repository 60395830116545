import rootStore from "@/store/index";
import { getUuid } from "./common";

export default class uploadImageHandler {
  constructor(options = {}) {
    this.options = options;
    this.uploadTokenObj = {};
    this.uploadUrl = "";
    this.uploadBusinessInfo = { businessType: "0" };
    this.begin();
  }
  getOssToken(file) {
    rootStore.dispatch("base/getUploadToken", this.uploadBusinessInfo).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${getUuid(15)}_${this.options.blobInfo.filename()}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
      this.uploadImage();
    });
  }
  uploadImage() {
    var xhr, formData;
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", this.uploadUrl);
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.options.success(this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key);
      } else {
        this.options.failure("HTTP Error: " + xhr.status);
      }
    };
    formData = new FormData();
    Object.keys(this.uploadTokenObj).forEach(key => {
      formData.append(key, this.uploadTokenObj[key]);
    });
    formData.append("file", this.options.blobInfo.blob());
    xhr.send(formData);
  }
  begin() {
    this.getOssToken();
  }
}
